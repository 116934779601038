export class AnnotationChapter {
  static DESIGN = new AnnotationChapter("1", "design", false, "#ADD8E6");
  static GROUP_NAME = new AnnotationChapter("2", "group", false, "#ADD8E6");
  static GROUP_SAMPLE_NUMBER = new AnnotationChapter("2", "N", false, "#ADD8E6");
  static SUBJECTS = new AnnotationChapter("3", "subjects", false, "#ADD8E6");
  static CONDITION = new AnnotationChapter("3", "condition", false, "#ADD8E6");
  static THERAPY = new AnnotationChapter("3", "therapy", false, "#ADD8E6");
  static PROCEDURE = new AnnotationChapter("3", "procedure", false, "#ADD8E6");
  static OUTCOME = new AnnotationChapter("4", "outcome", true);
  static PRIMARY_OUTCOME = new AnnotationChapter("4.1", "prim", true);
  static SECONDARY_OUTCOME = new AnnotationChapter("4.2", "sec", true, '#FFD580');
  static TERTIARY_OUTCOME = new AnnotationChapter("4.3", "ter", true, '#CBC3E3');
  static POST_HOC = new AnnotationChapter("4.4", "ph", true, '#D6B093');
  static CONTROL_VARIABLE = new AnnotationChapter("6", "control", true, '#D3D3D3');
  static ADVERSE_EVENT = new AnnotationChapter("7", "adverse", true, '#FFC0CB');
  static REGISTRY = new AnnotationChapter("10", "registry", false, "#FF474C");
  static ICD = new AnnotationChapter("11", "icd", false, "#FF474C");
  static LOCATION = new AnnotationChapter("12", "location", false, "#FF474C");

  static chapterList: Array<AnnotationChapter> = [
    AnnotationChapter.DESIGN,
    AnnotationChapter.GROUP_NAME,
    AnnotationChapter.GROUP_SAMPLE_NUMBER,
    AnnotationChapter.SUBJECTS,
    AnnotationChapter.CONDITION,
    AnnotationChapter.THERAPY,
    AnnotationChapter.PROCEDURE,
    AnnotationChapter.OUTCOME,
    AnnotationChapter.PRIMARY_OUTCOME,
    AnnotationChapter.SECONDARY_OUTCOME,
    AnnotationChapter.TERTIARY_OUTCOME,
    AnnotationChapter.POST_HOC,
    AnnotationChapter.CONTROL_VARIABLE,
    AnnotationChapter.ADVERSE_EVENT,
    AnnotationChapter.REGISTRY,
    AnnotationChapter.ICD,
    AnnotationChapter.LOCATION,
  ];

  static chapterVariableList: Array<string> = [
    AnnotationChapter.DESIGN.variableName,
    AnnotationChapter.GROUP_NAME.variableName,
    AnnotationChapter.GROUP_SAMPLE_NUMBER.variableName,
    AnnotationChapter.SUBJECTS.variableName,
    AnnotationChapter.CONDITION.variableName,
    AnnotationChapter.THERAPY.variableName,
    AnnotationChapter.PROCEDURE.variableName,
    AnnotationChapter.OUTCOME.variableName,
    AnnotationChapter.PRIMARY_OUTCOME.variableName,
    AnnotationChapter.SECONDARY_OUTCOME.variableName,
    AnnotationChapter.TERTIARY_OUTCOME.variableName,
    AnnotationChapter.POST_HOC.variableName,
    AnnotationChapter.CONTROL_VARIABLE.variableName,
    AnnotationChapter.ADVERSE_EVENT.variableName,
    AnnotationChapter.REGISTRY.variableName,
    AnnotationChapter.ICD.variableName,
    AnnotationChapter.LOCATION.variableName,
  ];

  chapter: string;
  variableName: string;
  isResult: boolean;
  color?: string

  constructor(chapter: string, variableName: string, isResult: boolean, color: string = '') {
    this.chapter = chapter;
    this.variableName = variableName;
    this.isResult = isResult;
    this.color = color;
  }
}
