import React, { Component } from "react";
import "../style/Highlight.css";
import type { LTWHP } from "../types.js";
import { getAnnotationColor } from "../../../utils/annotation/utils";

interface Props {
  position: {
    boundingRect: LTWHP;
    rects: Array<LTWHP>;
  };
  onClick?: () => void;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
  comment: {
    count: string;
    text: string;
  };
  isScrolledTo: boolean;
  annotationWriterType: any;
  isDuplicate: boolean;
  enableColorIdentification: boolean;
}

export class Highlight extends Component<Props> {
  calcRect = (rect: LTWHP, isDuplicate: boolean): LTWHP => {
    if (isDuplicate) {
      return {
        left: rect.left + 2,
        width: rect.width - 4,
        top: rect.top + 2,
        height: rect.height - 4,
      };
    }
    return rect;
  };

  render() {
    const {
      position,
      onClick,
      onMouseOver,
      onMouseOut,
      comment,
      isScrolledTo,
      annotationWriterType,
      isDuplicate,
      enableColorIdentification
    } = this.props;

    const { rects, boundingRect } = position;

    let className: string = `Highlight`;
    let annotationType = comment?.text?.split('.')[0];
    let backgroundColor = enableColorIdentification? getAnnotationColor(annotationWriterType, annotationType) : annotationWriterType?.color || "#FFFF00" ;
    let zIndex = isScrolledTo ? 5 : 1;

    return (
      <div
        className={`${className} ${
          isScrolledTo ? "Highlight--scrolledTo" : ""
        }`}
      >
        {comment ? (
          <div
            className="Highlight__emoji"
            style={{
              left: 20,
              top: boundingRect.top,
            }}
          >
            {/* {comment.count} */}
          </div>
        ) : null}
        <div className={"Highlight__parts"}>
          {rects.map((rect, index) => (
            <div
              onMouseOver={onMouseOver}
              onMouseOut={onMouseOut}
              onClick={onClick}
              key={index}
              // style={rect}
              style={{
                backgroundColor: backgroundColor,
                zIndex: zIndex,
                ...this.calcRect(rect, isDuplicate),
              }}
              className={`${className}__part`}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default Highlight;
