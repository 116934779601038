/**
 * Creates the objects for the
 */

import React from "react";
import { Box, Typography } from "@mui/material";
import {
  GroupResultContent,
  OutcomeSectionContent,
  OutcomeSectionResultContent,
  ResultTimePointContent,
  PublicationContent,
} from "../../../../../model/publicationdata/PublicationData";
import OutcomeResultContentComponent from "./OutcomeResultContentComponent";

interface OutcomeSectionResultTimeContentsComponentProps {
  section: OutcomeSectionContent;
  /**
   * from this we take only the list
   */
  result: OutcomeSectionResultContent;
  disableTextFields: boolean;
  updateContentWithSection: Function;
}

/**
 * simple outcome property of a result in the outcome like spec, measure or measure type
 */
export function OutcomeSectionResultTimeContentsComponent({
  section,
  result,
  disableTextFields,
  updateContentWithSection,
}: OutcomeSectionResultTimeContentsComponentProps) {
  /**
   * Here we save only the result time, the rest will be handled a level lower
   */
  const saveChanges = (
    groupResult: GroupResultContent,
    timePointIndex: number,
    groupIndex: number
  ) => {
    section.results[result.resultId - 1].resultTimePointContent[
      timePointIndex
    ].groupResultContent[groupIndex] = groupResult;
    updateContentWithSection(section);
  };

  const saveChangesTime = (
    publicationContent: PublicationContent,
    timePointIndex: number
  ) => {
    section.results[result.resultId - 1].resultTimePointContent[
      timePointIndex
    ].time = publicationContent;
    updateContentWithSection(section);
  };

  var resultTimes: any = [];
  for (var i: number = 0; i < result.resultTimePointContent.length; i++) {
    const resultTimePointContent: ResultTimePointContent =
      result.resultTimePointContent[i];
    if (resultTimePointContent !== null) {
      const index = i;
      resultTimes.push(
        <Box flexDirection="column" mb={1} mt={3}>
          <Typography variant="subtitle1">
            {resultTimePointContent.timepoint !== null
              ? "Time stamp: " + resultTimePointContent.timepoint
              : "Time stamp: Difference to baseline"}
          </Typography>
        </Box>
      );
      resultTimes.push(
        <OutcomeResultContentComponent
          publicationContent={resultTimePointContent.time}
          caption="Time"
          disableTextFields={disableTextFields}
          saveChanges={(e: any) => {
            saveChangesTime(e, index);
          }}
        />
      );
      for (
        var j = 0;
        j < resultTimePointContent.groupResultContent.length;
        j++
      ) {
        resultTimes.push(
          <GroupContentComponent
            groupResult={resultTimePointContent.groupResultContent[j]}
            disableTextFields={disableTextFields}
            indexOfResultTime={i}
            indexOfGroup={j}
            groups={resultTimePointContent.groupResultContent[j].groups}
            saveChanges={saveChanges}
          />
        );
      }
    }
  }

  return <React.Fragment>{resultTimes}</React.Fragment>;
}

interface GroupContentComponentProps {
  groupResult: GroupResultContent;
  disableTextFields: boolean;
  indexOfGroup: number;
  indexOfResultTime: number;
  groups: string;
  saveChanges: (
    groupResult: GroupResultContent,
    indexOfGroup: number,
    indexOfResultTime: number
  ) => void;
}

function GroupContentComponent({
  groupResult,
  disableTextFields,
  indexOfGroup,
  indexOfResultTime,
  groups,
  saveChanges,
}: GroupContentComponentProps) {
  const saveChangesLocal = (resultContent: PublicationContent) => {
    if (
      groupResult.result !== null &&
      groupResult.result.variableName !== null &&
      groupResult.result.variableName === resultContent.variableName
    ) {
      groupResult.result = resultContent;
    } else if (
      groupResult.sd !== null &&
      groupResult.sd.variableName !== null &&
      groupResult.sd.variableName === resultContent.variableName
    ) {
      groupResult.sd = resultContent;
    } else if (
      groupResult.ci95low !== null &&
      groupResult.ci95low.variableName !== null &&
      groupResult.ci95low.variableName === resultContent.variableName
    ) {
      groupResult.ci95low = resultContent;
    } else if (
      groupResult.ci95up !== null &&
      groupResult.ci95up.variableName !== null &&
      groupResult.ci95up.variableName === resultContent.variableName
    ) {
      groupResult.ci95up = resultContent;
    } else if (
      groupResult.iqrlow !== null &&
      groupResult.iqrlow.variableName !== null &&
      groupResult.iqrlow.variableName === resultContent.variableName
    ) {
      groupResult.iqrlow = resultContent;
    } else if (
      groupResult.iqrup !== null &&
      groupResult.iqrup.variableName !== null &&
      groupResult.iqrup.variableName === resultContent.variableName
    ) {
      groupResult.iqrup = resultContent;
    } else if (
      groupResult.pvalue !== null &&
      groupResult.pvalue.variableName !== null &&
      groupResult.pvalue.variableName === resultContent.variableName
    ) {
      groupResult.pvalue = resultContent;
    }
    saveChanges(groupResult, indexOfGroup, indexOfResultTime);
  };

  return (
    <React.Fragment>
      <OutcomeResultContentComponent
        publicationContent={groupResult.result}
        caption={"Result (" + groups + ")"}
        disableTextFields={disableTextFields}
        saveChanges={saveChangesLocal}
      />
      <OutcomeResultContentComponent
        publicationContent={groupResult.sd}
        caption={"SD (" + groups + ")"}
        disableTextFields={disableTextFields}
        saveChanges={saveChangesLocal}
      />
      <OutcomeResultContentComponent
        publicationContent={groupResult.se}
        caption={"SE (" + groups + ")"}
        disableTextFields={disableTextFields}
        saveChanges={saveChangesLocal}
      />
      <OutcomeResultContentComponent
        publicationContent={groupResult.ci95low}
        caption={"95CI low (" + groups + ")"}
        disableTextFields={disableTextFields}
        saveChanges={saveChangesLocal}
      />
      <OutcomeResultContentComponent
        publicationContent={groupResult.ci95up}
        caption={"95CI up (" + groups + ")"}
        disableTextFields={disableTextFields}
        saveChanges={saveChangesLocal}
      />
      <OutcomeResultContentComponent
        publicationContent={groupResult.iqrlow}
        caption={"IQR low (" + groups + ")"}
        disableTextFields={disableTextFields}
        saveChanges={saveChangesLocal}
      />
      <OutcomeResultContentComponent
        publicationContent={groupResult.iqrup}
        caption={"IQR up (" + groups + ")"}
        disableTextFields={disableTextFields}
        saveChanges={saveChangesLocal}
      />
      <OutcomeResultContentComponent
        publicationContent={groupResult.pvalue}
        caption={"p-value (" + groups + ")"}
        disableTextFields={disableTextFields}
        saveChanges={saveChangesLocal}
      />
    </React.Fragment>
  );
}

export default OutcomeSectionResultTimeContentsComponent;
