import { Grid, Typography } from "@mui/material";
import React from "react";
import {
  EditHighlightOperation,
  IHighlight,
} from "../../../libs/react-pdf-highlighter";
import { AnnotationType } from "../../../model/annotation/AnnotationType";
import { ContentAnnotationButtonRow } from "./ContentAnnotationButtonRow";
import { getAnnotationColor } from "../../../utils/annotation/utils";

/**
 * Content of the Annotations
 */

export function ContentAnnotation(
  textColor: string,
  highlight: IHighlight,
  removeAnnotation: Function,
  acceptAnnotationByAnnotator: Function,
  duplicateAnnotation: (editHighlightOperation: EditHighlightOperation) => void,
): JSX.Element {

  const emptyAdditionText =
    highlight.annotationType === AnnotationType.EMPTY.type ? " (e)" : "";
  
  const color = getAnnotationColor(highlight.annotationWriterType, highlight.comment.text);  

  return (
    <div>
      <Grid container spacing={3} style={{ marginTop: "-20px" }}>
        <Grid item xs={8}>
          <Typography
            style={{
              fontWeight: "bold",
              color: textColor,
            }}
          >
            {"(" +
              highlight.chapter +
              ") " +
              highlight.comment.text +
              emptyAdditionText}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <div
            style={{
              width: '25px',
              height: '25px',
              backgroundColor: color,
            }}
          ></div>
        </Grid>
        <Grid item xs={3} style={{ textAlign: "right" }}>
          Pg.{" "}
          {highlight.annotationType === AnnotationType.EMPTY.type
            ? 0
            : highlight.position.pageNumber}
        </Grid>
      </Grid>
      {highlight.content.text ? (
        <blockquote style={{ marginTop: "0.3rem" }}>
          {`${highlight.content.text.slice(0, 90).trim()}`}
        </blockquote>
      ) : null}
      {highlight.content.image ? (
        <div className="highlight__image" style={{ marginTop: "0.5rem" }}>
          <img src={highlight.content.image} alt={"Screenshot"} />
        </div>
      ) : null}
      {ContentAnnotationButtonRow(
        removeAnnotation,
        acceptAnnotationByAnnotator,
        duplicateAnnotation,
        highlight
      )}
    </div>
  );
}
